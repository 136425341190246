import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';


function Notification() {
    return (
        <>
        <ReactNotification />
        </>
    )
}

export default Notification;
import CustomWidgetHelper from "./customwidgethelper";

function init(Survey) {
  const widget = {
    name: "searchstring",
    isFit: function (question) {
      const names = [
        "manufacturer",
        "options",
        "comment",
        "left",
        "right",
        "side_enabled",
        "probes",
        "electrode_configuration",
        "amplitude_type",
        "amplitude_v",
        "amplitude_ma",
        "pulse_width",
        "frequency",
        "L1",
        "L2",
        "birthdate",
        "proceduredate",
        "pacientage",
      ];
      const types = ["inputdate", "panel", "expression", "nouislider"];

      if (!types.includes(question.getType()) && !names.includes(question.name))
        return true;

      return false;
    },
    init() {
      Survey.Serializer.addProperty("question", {
        name: "hasClearButton:switch",
        category: "general",
        default: true,
      });
    },
    isDefaultRender: true,
    afterRender: function (question, htmlElement) {
      const mainDiv = document.createElement("div");
      mainDiv.classList.add("additional-properties");

      const clearButton = CustomWidgetHelper.appendClearButton(
        question,
        htmlElement
      );

      mainDiv.appendChild(clearButton);

      htmlElement.parentElement.appendChild(mainDiv, htmlElement);

      const toggle =
        clearButton.getElementsByClassName("react-toggle")[0] || clearButton;

      const clearValueHandler = function () {
        if (
          typeof question.value === "object" &&
          ((question.value.nda && Object.keys(question.value).length === 1) ||
            (Array.isArray(question.value) &&
              question.value.length === 1 &&
              question.value[0] === null))
        ) {
          return;
        }

        toggle.classList.remove("react-toggle--checked");
        toggle.innerText = "No Data";
        htmlElement.classList.remove("cleared-question");
        question.survey.setValue(question.name + "_nda", undefined);

        if (
          typeof question.value === "object" &&
          Array.isArray(question.value) &&
          question.value[0] === null
        ) {
          question.survey.setValue(question.name, question.value.slice(1));
        } else if (typeof question.value === "object" && question.value.nda) {
          delete question.value["nda"];
        }
      };

      question.valueChangedCallback = clearValueHandler;
    },
  };
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget);
}

export default init;

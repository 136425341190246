import axios from 'axios';

//@TODO: add switch to https
//@TODO: for GET need string params after ?action1=3&action2='test'
class HttpConnector {
    constructor(params) {
        this.link = params.link;
        this.endpoint = params.endpoint;
        this.method = params.method || 'post'; //child prop to axios
        this.token = null;
        this.payload = null;
        this.config = {
            method: params.method || 'post', //child prop to axios
            baseURL: params.link,
            url: params.endpoint
        };
    }
    request(payload,callback) {
        const config = {
            method: this.method,
            baseURL: this.link,
            url: this.endpoint,
            data: payload
        };
        config.headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
        //if JWT exist add it to header
        if (window.token?.token_key) {
            config.headers.Authorization = 'Bearer ' + window.token?.token_key;
        }
        axios.request(config)
        .then(function (response) {
            callback(false, response.data);
        })
        .catch(function (error) {
            console.log(error);
            callback(error,false);
        });
    }
}

export default HttpConnector;
class ApiModel {
    constructor(connector) {
        this.connector = connector; //connector object
    }
    _connectApi(payload, callback) {
        this.connector.request(payload, (err, res) => {
            if (err) {
                console.log(err);
                if (typeof (callback) === 'function') {
                    callback(err, res);
                }
            } else {
                if (!res.success) {
                    if (res.api_error.type === "RECORD_VALIDATION_ERROR") {
                        alert('Validation error');
                    }
                    if (typeof (callback) === 'function') {
                        callback(res, false);
                    }
                } else {
                    if (typeof (callback) === 'function') {
                        callback(err, res);
                    }
                }
            }

        })
    }
}

export default ApiModel;
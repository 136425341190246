import React from 'react'
import { withTranslation } from 'react-i18next';

class Label extends React.Component{
    render () {
        let label_for = this.props.for || '';
        const { t } = this.props;
        return (
            <label htmlFor={label_for}>{t(this.props.title)}</label>
        );
    }
}

export default withTranslation()(Label);
import React from 'react'
import ToggleR from 'react-toggle'
import { withTranslation } from 'react-i18next';
import "react-toggle/style.css"

class Toggle extends React.Component{
    getNamePostfix() {
        return (this.props.postfix) ? this.props.name + '_' + this.props.postfix : this.props.name + '_edit';
    }
    getWrapClassName() {
        return "toggle field_wrap " + this.getNamePostfix();
    }
    isDisabled() {
        let disabled = false;
        if (this.props.status && this.props.status === 'disabled') {
            disabled = true;
        }
        return disabled;
    }
    isHidden() {
        let hidden = false;
        if (this.props.status && this.props.status === 'hidden') {
            hidden = true;
        }
        return hidden;
    }
    onChange(event) {
        this.props.onChange({name: this.props.name, value: event.target.checked, params: (this.props.params || null)});
    }
    showInput() {
        let checked_value = (this.props.value) ? true : false;
        return (
            <ToggleR
                checked={checked_value}
                id={this.getNamePostfix()} value={"1"} name={this.getNamePostfix()}
                onChange={this.onChange.bind(this)}
                disabled={this.isDisabled()}/>
        )
    }
    showError() {
        if(this.props.error && this.props.error['message']) {
            const { t } = this.props;
            return (<span className="error_tag">{t(this.props.error.message)}</span>)
        } else return null;
    }
    showLabel() {
        if (this.props.label) {
            const { t } = this.props;
            return (
                <label htmlFor={this.getNamePostfix()}>{t(this.props.label)}</label>
            )
        } else {
            return null;
        }
    }
    render() {
        if (this.isHidden()) {
            return null
        } else {
            return (
                <div className={this.getWrapClassName()}>
                    {this.showInput()}
                    {this.showLabel()}
                    {this.showError()}
                </div>
            )
        }
    }
}

export default withTranslation()(Toggle);
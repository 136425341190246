import React from 'react'
import { withTranslation } from 'react-i18next';

class FormHeader extends React.Component {
    render () {
        const { t } = this.props;
        return (
            <div className="form-header-wrap">
                <div className="form-header">{t(this.props.title)}</div>
            </div>
        )
    }
}

export default withTranslation()(FormHeader);
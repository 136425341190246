//check for keys duplication

function checkQuestions(page, question_names, question_info) {
  if (!page.elements) return;
  page.elements.forEach((question) => {
    question_info.qty++;
    if (!question_names[question.name]) question_names[question.name] = 1;
    else {
      question_names[question.name]++;
      question_info.uniqness = false;
      question_info.duplicates[question.name] = question_names[question.name];
    }
  });
}
function checkTemplate(template) {
  const pages_info = { qty: 0, uniqness: true, duplicates: {} };
  const questions_info = { qty: 0, uniqness: true, duplicates: {} };
  const pages_names = [];
  const question_names = [];
  if (!template.pages)
    return {
      status: pages_info.uniqness && questions_info.uniqness,
      pages_info: pages_info,
      questions_info: questions_info,
    };
  template.pages.forEach((page) => {
    pages_info.qty++;
    if (!pages_names[page.name]) pages_names[page.name] = 1;
    else {
      pages_names[page.name]++;
      pages_info.uniqness = false;
      pages_info.duplicates[page.name] = pages_names[page.name];
    }
    checkQuestions(page, question_names, questions_info);
  });
  return {
    status: pages_info.uniqness && questions_info.uniqness,
    pages_info: pages_info,
    questions_info: questions_info,
  };
}
function warningTemplate(template) {
  const template_stats = checkTemplate(template);

  if (template_stats.status) return null;

  let pages_error = "";
  if (!template_stats.pages_info?.uniqness) {
    const duplications = [];
    pages_error = "Pages duplications: ";
    Object.keys(template_stats.pages_info.duplicates).forEach((index) => {
      duplications.push(
        index + "(" + template_stats.pages_info.duplicates[index] + ")"
      );
    });
    pages_error += duplications.join(", ");
    pages_error += ". ";
  }
  let questions_error = "";
  if (!template_stats.questions_info?.uniqness) {
    const duplications = [];
    questions_error = "Questions duplications: ";
    Object.keys(template_stats.questions_info.duplicates).forEach((index) => {
      duplications.push(
        index + "(" + template_stats.questions_info.duplicates[index] + ")"
      );
    });
    questions_error += duplications.join(", ");
  }
  return pages_error + questions_error;
}

function getTranslation(translation, lang = "default") {
  return typeof translation === "object"
    ? translation?.[lang] || translation?.default
    : translation;
}

function getTitle(question, lang = "default") {
  const trans = getTranslation(question.title, lang) ?? "";
  return trans.length > 0 ? trans : question.name;
}

function templatePagesTitles(survey_model) {
  const options = [];
  survey_model.visiblePages.forEach((page, index) => {
    options.push({ value: index, label: index + 1 + ". " + getTitle(page) });
  });
  return options;
}

exports.checkTemplate = checkTemplate;
exports.warningTemplate = warningTemplate;
exports.templatePagesTitles = templatePagesTitles;
exports.getTitle = getTitle;
exports.getTranslation = getTranslation;

const leadprobes4 = {
  name: "leadprobes_v2",
  title: "Lead Probes",
  elementsJSON: [
    {
      type: "radiogroup",
      name: "side_enabled",
      title: "Enabled",
      defaultValue: "Yes",
      choices: ["Yes", "No"],
      colCount: 2,
    },
    {
      type: "matrixdropdown",
      hideAnsweredStatus: true,
      name: "probes",
      visibleIf: "{composite.side_enabled}='Yes'",
      columns: [
        {
          name: "electrode_configuration",
          title: {
            default: "Electrode configuration",
            de: "Electrode configuration",
          },
          cellType: "dropdown",
          showOptionsCaption: "false",
          defaultValue: "0",
          isRequired: false,
          choices: [
            {
              value: "+1",
              text: "+",
            },
            {
              value: "-1",
              text: "-",
            },
            {
              value: "0",
              text: "　",
            },
          ],
        },
      ],
      rows: ["C", "3", "2", "1", "0"],
    },
    {
      type: "radiogroup",
      hideAnsweredStatus: true,
      name: "amplitude_type",
      title: "Amplitude Type",
      visibleIf: "{composite.side_enabled}='Yes'",
      defaultValue: "V",
      choices: ["V", "mA"],
      colCount: 2,
    },
    {
      type: "nouislider",
      hideAnsweredStatus: true,
      name: "amplitude_v",
      title: "Amplitude, V",
      visibleIf:
        "{composite.side_enabled}='Yes' and {composite.amplitude_type}='V'",
      step: 0.1,
      defaultValue: 0.5,
      rangeMin: 0,
      rangeMax: 5,
      pipsMode: "values",
      pipsValues: [0, 1, 2, 3, 4, 5],
      pipsDensity: 100,
    },
    {
      type: "nouislider",
      hideAnsweredStatus: true,
      name: "amplitude_ma",
      title: "Amplitude, mA",
      visibleIf:
        "{composite.side_enabled}='Yes' and {composite.amplitude_type}='mA'",
      step: 0.1,
      defaultValue: 0.5,
      rangeMin: 0,
      rangeMax: 12.5,
      pipsMode: "values",
      pipsValues: [0, 2.5, 5, 6, 7.5, 10, 12.5],
      pipsDensity: 100,
    },
    {
      type: "nouislider",
      hideAnsweredStatus: true,
      name: "pulse_width",
      visibleIf: "{composite.side_enabled}='Yes'",
      title: "Pulse Width, microSec",
      step: 30,
      defaultValue: 210,
      rangeMin: 60,
      rangeMax: 450,
      pipsMode: "values",
      pipsValues: [60, 120, 180, 210, 240, 300, 360, 400, 450],
    },
    {
      type: "nouislider",
      hideAnsweredStatus: true,
      name: "frequency",
      visibleIf: "{composite.side_enabled}='Yes'",
      title: "Frequency, Hz",
      step: 1,
      defaultValue: 14,
      rangeMin: 2,
      rangeMax: 130,
      pipsMode: "values",
      pipsValues: [2, 14, 40, 70, 100, 130],
    },
  ],
  onCreated(question) {
    question.contentPanel.getQuestionByName("side_enabled").titleLocation =
      "hidden";
    question.contentPanel.getQuestionByName("probes").titleLocation = "hidden";
  },
  onAfterRender(question, htmlElement) {
    htmlElement.className += " section";
  },
};

export default leadprobes4;

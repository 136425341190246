import { openDialog } from "../custom_components/nativedialog";
import i18n from "i18next";

function init(Survey) {
  const widget = {
    name: "clearProbeSection",
    isFit: function (question) {
      const names = ["comment"];

      if (names.includes(question.name)) return true;

      return false;
    },
    init() {},
    isDefaultRender: true,
    afterRender: function (question, htmlElement) {
      const clearButton = document.createElement("div");
      clearButton.className = "btn toggle-1-icon field_wrap clear_button";
      clearButton.style =
        "color: black; flex-direction: row; justify-content: center; margin: 10px 0 0 auto; width: 100px;";
      clearButton.innerText = "Clear";

      htmlElement.parentElement.appendChild(clearButton, htmlElement);

      const clearValueHandler = function () {
        const answers = question.parentQuestionValue.value;

        if (!answers) return;

        delete answers[question.surveyDataValue.name];

        question.parentQuestionValue.value = answers;
      };

      clearButton.onclick = function () {
        const answers = question.parentQuestionValue.value;

        if (!answers || !answers[question.surveyDataValue.name]) return;

        openDialog(
          i18n.t("Are you sure you want to clear data?"),
          clearValueHandler,
          null
        );
      };
    },
  };
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget);
}

export default init;

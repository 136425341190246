import CustomWidgetHelper from "./customwidgethelper";

function init(Survey) {
  const widget = {
    name: "inputdate",
    title: "Input Date",
    iconName: "",
    widgetIsLoaded: function () {
      return true; //we do not require anything so we just return true.
    },
    isFit: function (question) {
      return question.getType() === "inputdate";
    },
    activatedByChanged: function (activatedBy) {
      Survey.JsonObject.metaData.addClass("inputdate", [], null, "empty");
      var properties = [
        {
          name: "dateFormat:string",
          category: "general",
          visible: true,
        },
      ];
      Survey.JsonObject.metaData.addProperties("inputdate", properties);
    },
    isDefaultRender: false,
    htmlTemplate: "<input class='form-control' type='date'/>",
    init() {
      Survey.Serializer.addProperty("inputdate", {
        name: "dateFormat:string",
        default: "dd.mm.yyyy",
        category: "general",
      });
    },
    afterRender: function (question, htmlElement) {
      if (question.isReadOnly) {
        const inputElement = htmlElement.getElementsByClassName("form-control");
        inputElement[0].disabled = true;
      }

      const mainDiv = document.createElement("div");
      if (!["birthdate", "proceduredate"].includes(question.name))
        mainDiv.classList.add("additional-properties");

      const clearButton = CustomWidgetHelper.appendClearButton(
        question,
        htmlElement
      );

      mainDiv.appendChild(clearButton);

      htmlElement.parentElement.appendChild(mainDiv, htmlElement);

      var input = htmlElement.getElementsByTagName("input")[0] || htmlElement;
      htmlElement.className += " input-date";

      var pushValueHandler = function () {
        question.value = input.value;
        clearValueHandler();
      };

      const toggle =
        clearButton.getElementsByClassName("react-toggle")[0] || clearButton;

      input.onfocusout = input.onchange = pushValueHandler;
      var clearValueHandler = function () {
        if (input.value) {
          toggle.classList.remove("react-toggle--checked");
          htmlElement.classList.remove("cleared-question");
          question.survey.setValue(question.name + "_nda", undefined);
        }
        question.value = input.value;
      };
      input.onkeyup = clearValueHandler;

      var updateHandler = function () {
        input.value =
          question.value === undefined || question.value === null
            ? ""
            : question.value;
      };
      question.valueChangedCallback = updateHandler;
      updateHandler();
    },
  };
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget);
}

export default init;

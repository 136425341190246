import React from 'react';

function WaitingForm() {
    return (
        <div className="form-wrap navigation">
            WAITING FOR DATA...
        </div>
    )
}

export default WaitingForm;

import React from 'react'
import { withTranslation } from 'react-i18next';

class Header extends React.Component {
    render () {
        const { t } = this.props;
        return (
            <h1 className="header">{t(this.props.title)}</h1>
        )
    }
}

export default withTranslation()(Header);
import React from 'react'
import { withTranslation } from 'react-i18next';

class FieldValue extends React.Component{
    render () {
        let div_className = (this.props.className) ? 'field-value ' + this.props.className : 'field-value';
        const { t } = this.props;
        const text = this.props.title || this.props.label || this.props.text || '';
        if (this.props.hide_on_null && !this.props.children) return null;
        return (
            <div className={div_className}><div className="title">{t(text)}</div><div className="value">{this.props.children}</div></div>
        );
    }
}

export default withTranslation()(FieldValue);
const leadMatrix8 = {
  name: "leadmatrix4left",
  title: "Lead Matrix 4 left",
  questionJSON: {
    type: "matrixdropdown",
    name: "left_section",
    columns: [
      {
        name: "manufacturer",
        title: { default: "Manufacturer", de: "Manufacturer" },
        cellType: "dropdown",
        showOptionsCaption: "false",
        choices: [
          {
            value: "medtronic",
            text: "Medtronic",
          },
          {
            value: "axion",
            text: "Axonics",
          },
          {
            value: "others",
            text: {
              default: "Others",
              de: "Andere",
            },
          },
        ],
      },
      {
        name: "options",
        title: { default: "Type of lead", de: "Type of lead" },
        cellType: "dropdown",
        showOptionsCaption: "false",
        choices: [
          {
            value: "test",
            text: "Test",
          },
          {
            value: "tined_lead",
            text: "Tined Lead",
          },
        ],
      },
      {
        name: "comment",
        placeHolder: {
          default: "Free text",
          de: "Freitext",
        },
        title: { default: "Comment", de: "Comment" },
        cellType: "text",
      },
    ],
    rows: ["S1", "S2", "S3", "S4"],
  },
  onAfterRender(question, htmlElement) {
    htmlElement.className += " left-section";
  },
};

export default leadMatrix8;

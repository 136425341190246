import { openDialog } from "../custom_components/nativedialog";
import i18n from "i18next";

class CustomWidgetHelper {
  static showClearButton = (question) => {
    return (
      question.hasClearButton &&
      !question.defaultValue &&
      !question.defaultValueExpression &&
      !question.isReadOnly &&
      !question.surveyValue.isDisplayMode
    );
  };

  static appendClearButton = (question, htmlElement) => {
    const nda_name = question.name + "_nda";
    const question_value = question.survey.getValue(nda_name);

    const button = document.createElement("div");
    button.className = "btn toggle-1-icon field_wrap clear_button";
    button.style =
      "flex-direction: row; justify-content: center; margin: 0; width: 150px;";

    function clearValueHandler() {
      if (typeof question.value === "object" && Array.isArray(question.value)) {
        question.survey.setValue(question.name, [null]);
      } else if (question.getType() === "leadprobes8v2") {
        question.survey.setValue(question.name, { nda: true });
      } else {
        question.survey.setValue(question.name, undefined);
      }

      button.classList.add("react-toggle--checked");
      button.innerText = "No Data ☑";
      htmlElement.classList.add("cleared-question");
      question.survey.setValue(nda_name, true);
    }

    button.onclick = function () {
      if (question.survey.getValue(nda_name)) return;

      if (
        question.value &&
        (typeof question.value !== "object" ||
          (Array.isArray(question.value) && question.value.length !== 0) ||
          (!Array.isArray(question.value) && !question.value.nda))
      ) {
        openDialog(
          i18n.t("Are you sure you want to clear data?"),
          clearValueHandler,
          null
        );
      } else {
        clearValueHandler();
      }
    };

    if (
      question_value &&
      (typeof question_value !== "object" ||
        (!question_value.nda &&
          (!Array.isArray(question_value) || question_value[0] !== null)))
    ) {
      button.classList.add("react-toggle--checked");
      htmlElement.classList.add("cleared-question");
      button.innerText = "No Data ☑";
    } else {
      button.classList.remove("react-toggle--checked");
      button.innerText = "No Data";
    }

    const setClearButtonVisibility = () => {
      button.style.display = !this.showClearButton(question) ? "none" : "";
    };

    setClearButtonVisibility();

    question.registerFunctionOnPropertyValueChanged(
      "hasClearButton",
      setClearButtonVisibility
    );

    question.registerFunctionOnPropertyValueChanged(
      "isRequired",
      setClearButtonVisibility
    );

    return button;
  };
}

export default CustomWidgetHelper;

import React from 'react'
import Cleave from 'cleave.js/react';
import Field from './fieldclass';
import { withTranslation } from 'react-i18next';

class InputField extends Field {
    componentDidMount() {
        if (this.props.autoFocus) {
            this.nameInput.focus();
        }
    }
    onKeyDown(event) {
        if (this.props['onEnter'] && event.key === 'Enter') {
            this.props.onEnter({ name: this.props.name, value: event.target.value })
        }
    }
    onChange(event) {
        let maxlength = (this.props.maxlength) ? Number(this.props.maxlength) + 1 : 1000;
        if (this.props['onChange'] && event.target.value.length < maxlength)
            this.props.onChange({ name: this.props.name, value: event.target.value })
    }
    showInput() {
        const { t } = this.props;
        let value = (this.props.value) ? this.props.value : '';
        let maxlength = (this.props.maxlength) ? this.props.maxlength : 1000;
        if (this.props.input_control) {
            return (
                <Cleave id={this.getNamePostfix()} name={this.getNamePostfix()} type={this.props.type} data-tip={this.props.tip} value={value}
                    placeholder={t(this.props.placeholder)}
                    options={this.props.input_control}
                    maxLength={maxlength}
                    autoCorrect="off"
                    autoCapitalize="none"
                    autoComplete={this.props.autoComplete || "off"}
                    ref={(input) => { this.nameInput = input; }}
                    onKeyDown={this.onKeyDown.bind(this)}
                    onChange={this.onChange.bind(this)}
                    disabled={this.isDisabled()} />
            )
        } else {
            return (
                <input id={this.getNamePostfix()} name={this.getNamePostfix()} data-tip={this.props.tip}
                    placeholder={t(this.props.placeholder)}
                    autoCorrect="off"
                    autoCapitalize="none"
                    autoComplete={this.props.autoComplete || "off"}
                    ref={(input) => { this.nameInput = input; }}
                    type={this.props.type}
                    onChange={this.onChange.bind(this)}
                    maxLength={maxlength}
                    onKeyDown={this.onKeyDown.bind(this)}
                    value={value}
                    disabled={this.isDisabled()} />
            )
        }
    }
}

export default withTranslation()(InputField);
import React from 'react';

function ErrorForm(props) {
    const error_msg = props.error_msg || 'Something wrong';
    return (
        <div className="form-wrap navigation">
            {error_msg}
        </div>
    )
}

export default ErrorForm;

import Inputmask from "inputmask";
import CustomWidgetHelper from "./customwidgethelper";

function init(Survey) {
  const widget = {
    name: "inputcustom",
    title: "Input Custom",
    iconName: "",
    widgetIsLoaded: function () {
      return true; //we do not require anything so we just return true.
    },
    willUnmount: function (question, el) {
      question.readOnlyChangedCallback = null;
    },
    isFit: function (question) {
      //we return true if the type of question is textwithbutton
      if (question.getType() === "inputcustom") return true;
      return false;
    },
    activatedByChanged: function (activatedBy) {
      Survey.JsonObject.metaData.addClass("inputcustom", [], null, "empty");
      var properties = [
        {
          name: "decimalPlaces:number",
          category: "general",
          visible: true,
        },
        {
          name: "minValue:number",
          category: "general",
          visible: true,
        },
        {
          name: "maxValue:number",
          category: "general",
          visible: true,
        },
        {
          name: "prefix",
          category: "general",
          visible: true,
        },
        {
          name: "suffix",
          category: "general",
          visible: true,
        },
      ];
      Survey.JsonObject.metaData.addProperties("inputcustom", properties);
    },
    isDefaultRender: false,
    htmlTemplate: "<input class='sv_q_text_root' type='text'/>",
    init() {
      Survey.Serializer.addProperty("inputcustom", {
        name: "minValue:number",
        category: "general",
      });
      Survey.Serializer.addProperty("inputcustom", {
        name: "maxValue:number",
        category: "general",
      });
      Survey.Serializer.addProperty("inputcustom", {
        name: "decimals:number",
        category: "general",
      });
    },
    afterRender: function (question, htmlElement) {
      if (question.isReadOnly) {
        const inputElement =
          htmlElement.getElementsByClassName("sv_q_text_root");
        if (inputElement[0]) inputElement[0].disabled = true;
      }

      const mainDiv = document.createElement("div");
      mainDiv.classList.add("additional-properties");

      const clearButton = CustomWidgetHelper.appendClearButton(
        question,
        htmlElement
      );

      mainDiv.appendChild(clearButton);

      htmlElement.parentElement.appendChild(mainDiv, htmlElement);

      const input = htmlElement.getElementsByTagName("input")[0] || htmlElement;

      htmlElement.className += " input-custom";
      const options = {};
      options.digits = question.decimalPlaces || 0;
      if (question.minValue !== undefined) options.min = question.minValue;
      if (question.maxValue !== undefined) options.max = question.maxValue;
      options.suffix = question.suffix || "";
      options.prefix = question.prefix || "";
      options.numericPlaceholder = "";
      options.rightAlign = false;
      options.showMaskOnFocus = false;
      options.showMaskOnHover = false;
      options.placeholder = "";
      options.radixPoint = ".";

      Inputmask("decimal", options).mask(input);

      const toggle =
        clearButton.getElementsByClassName("react-toggle")[0] || clearButton;

      var clearValueHandler = function () {
        if (input.value !== null && input.value !== undefined) {
          toggle.classList.remove("react-toggle--checked");
          htmlElement.classList.remove("cleared-question");
          question.survey.setValue(question.name + "_nda", undefined);
        }
        question.value = input.value;
      };

      input.onblur = clearValueHandler;

      var updateHandler = function () {
        if (question.value === undefined || question.value === null)
          input.value = "";
      };
      question.valueChangedCallback = updateHandler;
      if (question.value) input.value = question.value;
      updateHandler();

      if (question.isReadOnly) {
        input.setAttribute("disabled", true);
      }

      question.readOnlyChangedCallback = function () {
        if (question.isReadOnly) {
          input.setAttribute("disabled", true);
        } else {
          input.removeAttribute("disabled");
        }
      };
    },
  };
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget);
}

export default init;

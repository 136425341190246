import React from 'react'
import { withTranslation } from 'react-i18next';

class DivT extends React.Component{
    render () {
        let div_className = (this.props.className) ? 'divt ' + this.props.className : 'divt';
        const { t } = this.props;
        const text = this.props.title || this.props.label || this.props.text || '';
        return (
            <div className={div_className}>{t(text)}</div>
        );
    }
}

export default withTranslation()(DivT);
import Inputmask from "inputmask";

const inputPercents = {
    name: "inputpercents",
    iconName: "icon-matrix",
    title: "Input %",
    questionJSON: 
        {
            "type": "text",
            "inputType": "text"
        }
    ,
    onAfterRender(question, htmlElement) {
        htmlElement.className += ' input-percent';
        var input = htmlElement.querySelector("input") || htmlElement;

       Inputmask("decimal", {
            groupSeparator: '',
            suffix: "%",
            min: 0,
            max: 100,
            autoGroup: true,
            integerDigits: 5,
            digitsOptional: true,
        }).mask(input);
    }
}

export default inputPercents;
import React from 'react'
import { withTranslation } from 'react-i18next';
import icon_next_default from './../../static/common/arrow-next-grey.svg'

class Button2Icons extends React.Component{
    onClick(event) {
        if (this.props.type === 'submit') {
            event.preventDefault();
        }
        this.props.onClick({name: this.props.name, params: (this.props.params || {})});
    }
    render () {
        let button_className = "button btn btn-2-icons " + (this.props.className || '');
        let type = this.props.type || 'button';
        const { t } = this.props;
        const next_icon = this.props.icon2 || icon_next_default;
        switch(this.props.status) {
            case 'disabled':
                return (
                    <button id={this.props.name} className={button_className} name={this.props.name} disabled
                            type={type} onClick={this.onClick.bind(this)}><img alt="icon1" className="icon1" src={this.props.icon1}/><span className="label">{t(this.props.label)}</span><img alt="icon1" className="icon2" src={next_icon}/>
                    </button>
                );
            case 'hidden':
                return null;
            default:
                return (
                    <button id={this.props.name} className={button_className} name={this.props.name}
                            type={type} onClick={this.onClick.bind(this)}><img alt="icon1" className="icon1" src={this.props.icon1}/><span className="label">{t(this.props.label)}</span><img alt="icon2" className="icon2" src={next_icon}/>
                    </button>
                );
        }
        
    }
}

export default withTranslation()(Button2Icons);
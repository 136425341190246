import React from "react";
import { useTranslation } from "react-i18next";

export function openDialog(message, confirmCallback, cancelCallback) {
  const nativeDialogRef = document.getElementById("nativedialog");

  const nativeDialogMessage =
    nativeDialogRef.getElementsByClassName("message")[0];

  nativeDialogMessage.innerHTML = message;

  const nativeDialogCancelButton =
    nativeDialogRef.getElementsByClassName("cancel")[0];

  nativeDialogCancelButton.onclick = null;

  if (typeof cancelCallback === "function")
    nativeDialogCancelButton.onclick = cancelCallback;

  const nativeDialogConfirmButton =
    nativeDialogRef.getElementsByClassName("confirm")[0];

  nativeDialogConfirmButton.onclick = null;

  if (typeof confirmCallback === "function")
    nativeDialogConfirmButton.onclick = confirmCallback;

  nativeDialogRef.showModal();
}

const NativeDialog = () => {
  const { t } = useTranslation();

  return (
    <dialog id="nativedialog">
      <form className="nativedialog_container">
        <div className="nativedialog_body">
          <div className="nativedialog message" />
        </div>
        <div className="nativedialog_footer">
          <button
            className="btn btn-primary nativedialog cancel"
            formMethod="dialog"
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary nativedialog confirm"
            formMethod="dialog"
          >
            {t("Confirm")}
          </button>
        </div>
      </form>
    </dialog>
  );
};

export default NativeDialog;
